"use client";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Show,
} from "@chakra-ui/react";
import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

const ModalContext = createContext([() => {}, () => {}]);

export const useModal = () => {
  return useContext(ModalContext);
};

const defaultModalData = {
  title: "",
  props: {},
  body: null,
  customFooter: null,
  isCompact: false,
  placement: "bottom",
  isOpen: true,
  size: "md",
  onClose: () => {},
};

export const ModalContextProvider = ({ children = <></> }) => {
  const { t } = useTranslation();
  const [modals, setModals] = useState([]);

  const openModal = (modalProps) => {
    setModals((old) => [...old, { ...defaultModalData, ...modalProps }]);
  };

  const closeModal = (index) => {
    let onCloseAction;
    setModals((old) => {
      return old.map((item, i) => {
        if (i === index) {
          onCloseAction = item.onClose;
          return { ...item, isOpen: false };
        }
        return { ...item };
      });
    });
    setTimeout(() => {
      setModals((old) => old.slice(0, old.length - 1));
      if (onCloseAction) onCloseAction();
    }, [300]);
  };

  const closeAllModals = () => {
    setModals((old) => {
      return old.map((item) => {
        return { ...item, isOpen: false };
      });
    });
    setTimeout(() => {
      setModals([]);
    }, [300]);
  };

  return (
    <ModalContext.Provider value={[openModal, closeModal, closeAllModals]}>
      {children}
      <Show above="lg">
        {modals.length > 0 &&
          modals.map((modal, index) => {
            return (
              <Modal
                key={`modal${index}`}
                isOpen={modal.isOpen}
                onClose={() => closeModal(index)}
                size={modal.size}
                closeOnOverlayClick={false}
                {...modal.props}
              >
                <ModalOverlay />
                <ModalContent pb={modal.isCompact ? 4 : 0}>
                  <ModalHeader textAlign="center" fontSize={32}>
                    {modal.title}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>{modal.body}</ModalBody>
                  {!modal.isCompact && (
                    <ModalFooter>
                      {modal.customFooter ? (
                        modal.customFooter
                      ) : (
                        <Button
                          onClick={() => closeModal(index)}
                          variant="outline"
                        >
                          {t("Cancel")}
                        </Button>
                      )}
                    </ModalFooter>
                  )}
                </ModalContent>
              </Modal>
            );
          })}
      </Show>
      <Show below="lg">
        {modals.length > 0 &&
          modals.map((modal, index) => {
            return (
              <Drawer
                key={`modal${index}`}
                placement={modal.placement}
                onClose={() => closeModal(index)}
                isOpen={modal.isOpen}
                closeOnOverlayClick={false}
                size={
                  ["left", "right"].includes(modal.placement) ? "full" : "xs"
                }
              >
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerHeader>
                    {modal.placement === "right" ? (
                      <HStack>
                        <IconButton
                          icon={<ChevronLeftIcon boxSize={6} />}
                          variant="unstyled"
                          onClick={() => closeModal(index)}
                        />
                        <Heading fontSize={20} flexGrow={1} textAlign="center">
                          {modal.title}
                        </Heading>
                      </HStack>
                    ) : (
                      <HStack>
                        <Heading fontSize={20} flexGrow={1}>
                          {modal.title}
                        </Heading>
                        <DrawerCloseButton pos="unset" />
                      </HStack>
                    )}
                  </DrawerHeader>
                  <DrawerBody>{modal.body}</DrawerBody>
                </DrawerContent>
              </Drawer>
            );
          })}
      </Show>
    </ModalContext.Provider>
  );
};
