import JobCardPlaceholder from "@/app/_components/share/JobCardPlaceholder";
import { JOB_INFORMATION_DETAIL_PATH } from "@/services/constants/routerPath";
import { Box, Card, Heading, Link, Stack } from "@chakra-ui/react";

export default function CategoryJobCard({
  id = "",
  corporateId = "",
  imageUrl = "",
  logoUrl = "",
  subTitle = "",
  title = "",
}) {
  return (
    <Card overflow="hidden" minH={310}>
      {id ? (
        <Link href={JOB_INFORMATION_DETAIL_PATH + id} as={Stack}>
          {/* <Image
            fallback={<Skeleton h={130} />}
            src={imageUrl}
            alt="Job thumb"
            h={200}
            objectFit="cover"
            className={!imageUrl && "fluid_banner_background"}
          /> */}
          <Box
            //aspectRatio="500 / 310"
            height={{ base: "400px", md: "310px" }}
            bgImage={imageUrl}
            bgSize="cover"
            bgPos="center"
            w="full"
            className={!imageUrl && "fluid_banner_background"}
            onClick={() =>
              (window.location.href = JOB_INFORMATION_DETAIL_PATH + id)
            }
          ></Box>
          <Stack padding={4} alignItems="flex-start">
            {/* <Img alt="logo" h={50} src={logoUrl} objectFit="contain" /> */}
            <Stack gap={0}>
              {/* <Link href={CORPORATE_DETAIL_PATH + corporateId}>
                <Text fontSize="xs">{subTitle}</Text>
              </Link> */}
              <Link href={JOB_INFORMATION_DETAIL_PATH + id}>
                <Heading size={18} noOfLines={2}>
                  {title}
                </Heading>
              </Link>
            </Stack>
          </Stack>
        </Link>
      ) : (
        <JobCardPlaceholder flexGrow={1} />
      )}
    </Card>
  );
}
