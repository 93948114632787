export const HOMEPAGE_PATH = "/home";
export const CORPORATE_HOMEPAGE_PATH = "/home-corporate";

export const INTRODUCTION_PAGE_PATH = "/introduction";

export const SIGNIN_PAGE = "/sign-in";
export const SIGNUP_PAGE = "/sign-up";
export const FIND_ID_FORGOT_PW_PAGE = "/recover-account";

export const SERVICE_INTRODUCTION_PATH = "/service-introduction";
export const VIDEO_PRODUCT_PROCESS_PATH =
  "/service-introduction/video-product-process";
export const CORPORATE_INFORMATION_PATH = "/corporate-information";
export const JOB_INFORMATION_PATH = "/job-information";
export const TALENT_INFORMATION_PATH = "/talent-information";
export const JOB_INFORMATION_DETAIL_PATH = "/job/";
export const CORPORATE_DETAIL_PATH = "/corporate/";
export const TALENT_DETAIL_PATH = "/talent/";
export const PERSONAL_DETAIL_PATH = "/personal/";
export const CORPORATE_REVIEW_PATH = "/corporate-review/";
export const SUCCESSFUL_CANDIDATE_REVIEW_PATH = "/successful-candidate-review/";

export const CORPORATE_MY_PAGE = {
  INFORMATION: "/corporate/information",
  JOB_REGISTRATION: "/corporate/job-recruitment-registration",
  LIST_OF_JOB_RECRUITMENT: "/corporate/list-of-job-recruitment",
  MEMBER_MANAGEMENT: "/corporate/member-management",
  LIST_OF_APPLICANT: "/corporate/applicant",
  APPLICANT_DETAILS_PATH: "/corporate/applicant/",
  VIDEO_INTERVIEW_APPLICANT: "/corporate/video-interview-applicant",
  VIDEO_INTERVIEW_TALENT: {
    PERSONAL_OF_INTEREST:
      "/corporate/video-interview-talent/personal-of-interest",
    PERSONAL_OF_INTEREST_FOR_COMPANY:
      "/corporate/video-interview-talent/personal-of-interest-for-company",
  },
  PAYMENT_HISTORY: "/corporate/payment-history",
  LIST_OF_TALENT: "/corporate/list-of-talent",
  CANDIDATE_REVIEW: "/corporate/candidate-review",
  NEW_CANDIDATE_REVIEW: "/corporate/candidate-review/new",
  JOB_RECRUITMENT_REVIEW: "/corporate/list-of-job-recruitment/",
  JOB_RECRUITMENT_EDIT: "/corporate/list-of-job-recruitment/edit/",

  ADVERTISMENT_PRODUCT_INFORMATION:
    "/corporate/advertisement-product-information",
  ADVERTISMENT_PRODUCT_REGISTER: "/corporate/advertisement-product-register",
};

export const PERSONAL_MY_PAGE = {
  INFORMATION: "/personal/information",
  RESUME: "/personal/resume",
  NEW_RESUME: "/personal/resume/new",
  EDIT_RESUME: "/personal/resume/edit",
  INTRODUCTION_VIDEO: "/personal/introduction-video",
  EDIT_INTRODUCTION_VIDEO: "/personal/introduction-video/edit/",
  NEW_INTRODUCTION_VIDEO: "/personal/introduction-video/new",
  APPLIED_JOB_LIST: "/personal/applied",
  INTERVIEW_JOB_DETAIL: "/personal/interview/",
  INTERESTED_CORPORATE: "/personal/interested-corporate",
  INTERESTED_RECRUITMENT: "/personal/interested-recruitment",
  LIST_OF_OFFER: "/personal/list-of-offer",
};

export const NORMAL_SEARCH = "/search";
export const NORMAL_SEARCH_JOBS = "/search/recruitment";
export const NORMAL_SEATCH_CORPORATE = "/search/corporate";

export const ADS_SERVICE = "/advertisement-service";
export const ADS_QUOTATION = "/quotation";

export const PAYMENT_STATE = "/payment/";

export const SERVICE_CENTER = "/service-center";
export const SERVICE_CENTER_PATHS = {
  QNA: `${SERVICE_CENTER}/QNA`,
  INQUIRY_COUNSEL: `${SERVICE_CENTER}/inquiry-counsel`,
  NOTICE_BOARD: `${SERVICE_CENTER}/notice-board`,
  EVENT_SURVEY: `${SERVICE_CENTER}/event-survey`,
  RECOVER_ACCOUNT: `${SERVICE_CENTER}/recover`,
  MEMBERSHIP_WITHDRAWAL: `${SERVICE_CENTER}/membership-withdrawal`,
};

export const JOB_HOT_TEN_PATH = "/hot-ten/job";
export const CANCEL_MEMBERSHIP = "/cancel-account";
export const CANCEL_MEMBERSHIP_SUCCESS = "/cancel-account-success";
