import { DATE_FORMAT_DEFAULT } from "@/services/constants/global";
import { format as dateFormatter, isValid } from "date-fns";
import { get } from "lodash";
export const parseCustomDate = (value) => {
  if (!value) return null;

  if (value.length === 8) {
    const year = parseInt(value.slice(0, 4), 10);
    const month = parseInt(value.slice(4, 6), 10) - 1;
    const day = parseInt(value.slice(6, 8), 10);

    const parsedDate = new Date(year, month, day);

    if (isValid(parsedDate)) return parsedDate;
  }

  return null;
};

export const formatDate = (date, format = DATE_FORMAT_DEFAULT) => {
  if (!date) return;
  return dateFormatter(new Date(date), format);
};

export function objectToFormData(obj) {
  const formData = new FormData();

  Object.entries(obj).forEach(([key, value]) => {
    if (value === undefined || value === null) return;
    if (Array.isArray(value)) {
      if (value.length === 0) return;
      if (value[0] instanceof File) {
        value.forEach((item) => {
          const file = new File([item], encodeURIComponent(item.name), {
            type: item.type,
          });
          formData.append(key, file);
        });
        return;
      }
      formData.append(key, JSON.stringify(value));
      return;
    }

    formData.append(key, value);
  });

  return formData;
}

export const upperSnakeCaseToNormalCase = (string) => {
  if (!string) return "";
  const removedUnderscore = string.replace(/_/g, " ");
  return removedUnderscore[0] + removedUnderscore.slice(1).toLowerCase();
};

export const objectTrimmer = (obj) => {
  Object.keys(obj).forEach(
    (k) => (obj[k] = typeof obj[k] == "string" ? obj[k].trim() : obj[k])
  );
  return obj;
};

export const durationFormatter = (duration = [], separate = ":") => {
  if (!duration.length) return;
  return duration
    .map((item) => item.toString().padStart(2, "0"))
    .join(separate);
};

export const formatNumber = (number) =>
  number ? Intl.NumberFormat().format(number) : number;

export const formatMoneyAmount = (amount) =>
  Intl.NumberFormat("ko-KO", { style: "currency", currency: "KRW" }).format(
    amount
  );

export const sortByKey = (array, key) => {
  return array.sort((a, b) => {
    const x = key ? get(a, key).toUpperCase() : a.toUpperCase();
    const y = key ? get(b, key).toUpperCase() : b.toUpperCase();
    return x < y ? -1 : x > y ? 1 : 0;
  });
};

export const camelToFlat = (camel) => {
  const camelCase = camel.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ");

  let flat = "";

  camelCase.forEach((word) => {
    flat = flat + word.charAt(0).toUpperCase() + word.slice(1) + " ";
  });
  return flat;
};

export const formatPhoneNumber = (input, type) => {
  if (!input) return "";
  let regex = /(?<=^\d{3}|^\d{7})(?=\d)/g;
  const rawValue = input.replaceAll("-", "");
  const length = rawValue.length;

  if (type === "land") {
    if (length <= 9) {
      regex = /(?<=^\d{2}|^\d{5})(?=\d)/g;
    }
    if (length === 10) {
      regex = /(?<=^\d{3}|^\d{6})(?=\d)/g;
    }
    if (length === 11 && rawValue.slice(0, 3) !== "010") {
      regex = /(?<=^\d{4}|^\d{7})(?=\d)/g;
    }
    if (length >= 12) {
      regex = /(?<=^\d{4}|^\d{8})(?=\d)/g;
    }
  }

  const result = regex[Symbol.replace](rawValue, "-");
  return result;
};
